import React from "react"
import BlockContent from "@sanity/block-content-to-react"

import { DescriptionWrapper } from "src/layout/styles"

const TextBlock = ({ content, wrapper = DescriptionWrapper }) => {
  if (!content?.length) return null
  const WrapperComponent = wrapper
  return content.map((block, index) => (
    <WrapperComponent key={index}>
      <BlockContent blocks={block} />
    </WrapperComponent>
  ))
}

export default TextBlock
