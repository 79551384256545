import React from "react"

import ImageLightbox from "src/components/ImageLightbox"

function ProjectImages({ images = [] }) {
  if (!images.length) return null
  const imagesWithAspect = images.map(image => {
    const aspect =
      image.asset.metadata.dimensions.aspectRatio < 1 ? "portrait" : "landscape"
    return { image, aspect }
  })

  return (
    <>
      {imagesWithAspect.map(({ image, aspect }) => (
        <ImageLightbox
          image={image}
          aspect={aspect}
          key={`${image.asset._id}-project-image`}
        />
      ))}
    </>
  )
}

export default ProjectImages
