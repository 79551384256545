import React from "react"
import { motion } from "framer-motion"
import styled, { createGlobalStyle } from "styled-components"
import { useSelector } from "react-redux"

import { getProjectFromSlug } from "src/store"

import {
  ProjectPageGrid,
  TitleWrapper,
  Title,
  Meta,
  ProjectWrapper,
  MetaWrapper,
} from "src/layout/styles"
import TextBlock from "src/components/TextBlock"
import Image from "src/components/Image"
import RelatedProjects from "src/components/RelatedProjects"

import useProjectData from "src/hooks/useProjectData"
import useTransition from "src/hooks/useTransition"
import ProjectImages from "src/components/ProjectImages"
import { sizes } from "../../components/Image"

const ProjectPage = ({ params: { slug__current: slug }, location }) => {
  const project = useSelector(getProjectFromSlug(slug))
  const { alt, hero, bgColor, textColor } = useProjectData(project)
  const { STANDARD_TRANSITION } = useTransition()

  if (!project?.title) return null

  const year = getYear()

  return (
    <ProjectWrapper
      layoutId={`${slug}-${location?.state?.prev}`}
      transition={STANDARD_TRANSITION}
      animate={{ transitionEnd: { bottom: 0 } }}
      exit={{ opacity: 0, bottom: "unset" }}
    >
      <motion.div>
        <Image image={hero} alt={alt} sizes={sizes.fullWidth} />
      </motion.div>
      <GlobalStyles colors={{ bgColor, textColor }} />
      <motion.div
        initial={{ height: 0, overflow: "hidden" }}
        animate={{
          height: "auto",
          overflow: "visible",
          transition: { delay: 0.5 },
        }}
        exit={{ display: "none" }}
        transition={STANDARD_TRANSITION}
      >
        <TitleWrapper>
          <Title>
            <ArtistInner>{`${project.artist.name}`}</ArtistInner>
            <TitleInner>{`${project.title}`}</TitleInner>
          </Title>
          <Meta>{project.category.name}</Meta>
        </TitleWrapper>
        <ProjectPageGrid>
          <TextBlock content={project?._rawDescription} />
          <ProjectImages images={project?.images} />
          <TextBlock
            content={project?._rawMetaInformation}
            wrapper={MetaWrapper}
          />
        </ProjectPageGrid>
        <RelatedProjects projects={project?.relatedProjects} parent={slug} />
        <Copyright>&copy; Punkt {year}</Copyright>
      </motion.div>
    </ProjectWrapper>
  )
}

const getYear = () => {
  const date = new Date()
  return date.getFullYear()
}

const ArtistInner = styled.div``
const TitleInner = styled.div`
  font-family: var(--font-medium-italic);
`

const Copyright = styled.div`
  font-size: var(--small-type);
  color: var(--colour-text);
  border-top: 1px solid var(--colour-text);
  margin: 0 var(--large-space) var(--large-space);
  padding-top: 0.5em;
  opacity: 0.3;
`

const GlobalStyles = createGlobalStyle`
:root {
  ${({ colors }) => colors.bgColor && `--colour-bg: rgb(${colors.bgColor});`}
  ${({ colors }) =>
    colors.textColor && `--colour-text: rgb(${colors.textColor});`}
}
`

export default ProjectPage
