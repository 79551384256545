import React, { useState, useRef, useEffect } from "react"
import { useDispatch } from "react-redux"
import { motion, useDomEvent, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import { window } from "browser-monads"
import { camelCase as _camelCase } from "lodash"

import Image from "src/components/Image"
import useTransition from "src/hooks/useTransition"
import { sizes } from "./Image"

const ImageLightbox = ({ image, aspect }) => {
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const { STANDARD_TRANSITION } = useTransition()

  useEffect(() => {
    dispatch({ type: "setModalMode", payload: isOpen })
  }, [isOpen, dispatch])

  useDomEvent(useRef(window), "wheel", () => isOpen && setOpen(false))

  return (
    <div className={`${image.style} ${aspect}`}>
      <AnimatePresence>
        {!isOpen && (
          <ImageWrapper
            onClick={() => setOpen(!isOpen)}
            layoutId={`${image.asset._id}-lightbox`}
            transition={STANDARD_TRANSITION}
            className={`${image.style} ${aspect}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, zIndex: 1002, transitionEnd: { zIndex: 0 } }}
            exit={{ opacity: 0 }}
          >
            <motion.div>
              <Image
                image={image.asset}
                alt=""
                sizes={sizes[_camelCase(image.style)]}
              />
            </motion.div>
          </ImageWrapper>
        )}
        {isOpen && (
          <>
            <ModalImageWrapper
              onClick={() => setOpen(!isOpen)}
              layoutId={`${image.asset._id}-lightbox`}
              transition={STANDARD_TRANSITION}
              className={`${image.style} ${aspect}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, zIndex: 1002 }}
              exit={{ opacity: 0 }}
              aspect={aspect}
            >
              <Image image={image.asset} alt="" sizes={sizes.singleColumn} />
            </ModalImageWrapper>
            <ModalBackground />
          </>
        )}
      </AnimatePresence>
    </div>
  )
}

// const Modal = styled(motion.div)`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   pointerevents: ${({ isOpen }) => (isOpen ? "auto" : "none")};
//   opacity: 1;
//   background: var(--colour-bg);
// `

const ImageWrapper = styled(motion.div)`
  cursor: zoom-in;
  position: relative;
`

const ModalImageWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  max-width: ${({ aspect }) => (aspect === "landscape" ? "100%" : "none")};
  max-height: ${({ aspect }) => (aspect === "portrait" ? "100%" : "none")};
  cursor: zoom-out;
  display: flex;
  justify-content: center;
  align-items: center;
`
// & .gatsby-image-wrapper {
//   background-color: var(--colour-bg) !important;
//   width: 100%;
//   height: 100%;
//   div[data-placeholder-image] {
//     visibility: hidden;
//   }
//   img {
//     object-fit: contain !important;
//     background-color: transparent !important;
//   }
// }

const ModalBackground = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background-color: var(--colour-bg);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`
export default ImageLightbox
