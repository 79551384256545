import React from "react"
import styled from "styled-components"
import { media } from "src/layout/mediaQueries"

import ProjectThumbnail from "src/components/ProjectThumbnail"
import { sizes } from "./Image"

function RelatedProjects({ projects = [], parent }) {
  return (
    <>
      {projects.length > 0 ? (
        <>
          <RelatedProjectsWrapper>
            <RelatedProjectsTitle>Related projects</RelatedProjectsTitle>
            {projects.map(project => (
              <RelatedProjectsGrid
                key={`${project._id}-${parent}-relatedprojects`}
              >
                <ProjectThumbnail
                  project={project}
                  prev={"relatedprojects"}
                  sizes={sizes.relatedProject}
                />
              </RelatedProjectsGrid>
            ))}
          </RelatedProjectsWrapper>
        </>
      ) : null}
    </>
  )
}

const RelatedProjectsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--small-space);
  margin: 5rem var(--small-space);
  align-content: center;
  overflow: hidden;
  ${media.tablet`
  min-height: 80vh;
    gap: var(--large-space);
    margin: var(--large-space);
  `};
`

const RelatedProjectsGrid = styled.div`
  grid-column-end: span 10;
  grid-column-start: 2;
  ${media.largeMobile`
  grid-column-end: span 5;
  &:nth-child(odd) {
    grid-column-start: 7;
  }
  &:nth-child(even) {
    grid-column-start: 2;
  }
  `};
  ${media.smallTablet`
    grid-column-end: span 4;
    &:nth-child(even) {
      grid-column-start: 3;
    }
  `};
`

const RelatedProjectsTitle = styled.div`
  font-family: var(--font-medium);
  font-size: var(--small-type);
  text-align: left;
  grid-column-start: 1;
  grid-column-end: span 12;
  padding-bottom: 2rem;
  ${media.smallTablet`
    font-size: var(--medium-type);
  `};
`

export default RelatedProjects
